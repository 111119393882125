<template>
  <div class="main-content">
    <div class="content-block">
      <h2 class="lg-title nb">
        <div class="title-word">
          產品追加
          <div
            class="helper"
            data-toggle="modal"
            data-target="#modal-helper-product-added"
          ></div>
        </div>
      </h2>
      <div class="data-container list add-list">
        <div class="data-body">
          <template v-for="mainProductGroup in mainProducts">
            <div
              class="data-row edit-mode"
              v-for="(product, index) in mainProductGroup"
              :key="`products-sum-list-product-${product.id}`"
            >
              <div class="data-top">
                <div class="item product">
                  {{ product.name }}
                  <small style="color:#E30000;">
                    ({{ product.spec_name }})
                  </small>
                </div>
                <div class="item tool-wrap">
                  <GroupProductQtyInput
                    :product-index="index"
                    :quantity="product.additional_quantity"
                    @quantity-change="changeProductQty"
                  ></GroupProductQtyInput>
                </div>
              </div>
              <div class="data-bottom">
                <div class="item qty">
                  {{ product.quantity }}
                  <span>{{ product.unit }}</span>
                </div>
                <template v-if="index === mainProductGroup.length - 1">
                  <div class="item qty-detail">
                    <div class="text">
                      {{ product.unit_quantities.unit.quantity }}
                      <span>{{ product.unit_quantities.unit.text }}</span>
                      <template
                        v-if="product.unit_quantities.mini.quantity > 0"
                      >
                        <e>{{ product.unit_quantities.mini.quantity }}</e>
                        <span>{{ product.unit_quantities.mini.text }}</span>
                      </template>
                    </div>
                  </div>
                  <div class="item price">
                    ${{
                      orderWebsite
                        ? product.product_from_web_subtotal
                        : product.subtotal
                    }}
                  </div>
                </template>
              </div>
            </div>
          </template>
          <div class="sum-block">${{ groupSubtotal }}</div>
        </div>
      </div>
    </div>
    <div class="bottom-btn-block">
      <a class="btn-cta" @click.prevent="submit">確認</a>
    </div>
    <div
      class="modal fade"
      id="modal-helper-product-added"
      tabindex="-1"
      role="dialog"
      aria-labelledby="delalert"
    >
      <div class="modal-dialog modal-sm modal-helper">
        <div class="modal-content">
          <div class="modal-title">產品追加</div>
          <div class="helper-content">
            商品數量必須以商品的最大單位訂購，若數量不足或超過請使用增減單作業或追加
            ，將商品湊滿整數
          </div>
          <div class="modal-btn-block">
            <div class="modal-btn" data-dismiss="modal">確定</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GroupPurchaseMixin from '@/mixins/group-purchase';
import GroupProductQtyInput from '@/components/GroupProductQtyInput';
import {
  addGroupBuyingProductsAdditionalQty,
  getProductUnitPrice
} from '@/api/group-buying';
import { sumBy, debounce } from 'lodash';

export default {
  mixins: [GroupPurchaseMixin],
  components: {
    GroupProductQtyInput
  },
  computed: {
    postData() {
      return this.groupProducts.map(element => {
        return {
          id: element.id,
          additional_quantity: element.additional_quantity
        };
      });
    },
    groupSubtotal() {
      return sumBy(this.groupProducts, 'subtotal');
    }
  },
  methods: {
    changeProductQty(prop) {
      let product = this.groupProducts[prop.index];
      let quantity = prop.quantity;

      product.additional_quantity = quantity;
      product.actual_quantity = product.quantity + quantity;

      this.orderWebsite
        ? this.updateProductPriceRange(product)
        : this.updateProductPrice(product);
    },
    updateProductPriceRange: debounce(function(product) {
      getProductUnitPrice({
        group_buying_order_id: this.groupId,
        group_buying_product_id: product.id,
        actual_quantity: product.actual_quantity,
        order_from: this.orderFrom
      }).then(data => {
        this.groupProducts.forEach(specProduct => {
          if (
            specProduct.main_product_id !== null &&
            specProduct.main_product_id === product.main_product_id
          ) {
            specProduct.unit_price = data.unit_price;
            specProduct.unit_quantities = data.unit_quantities;

            //規格商品變更的本體價錢
            if (specProduct.id === product.id) {
              specProduct.subtotal =
                data.unit_price * data.unit_quantities.unit.quantity;
            } else {
              specProduct.subtotal = 0;
            }
          }
        });
      });
    }, 500),
    updateProductPrice(product) {
      product.subtotal = product.unit_price * product.actual_quantity;
    },
    submit() {
      addGroupBuyingProductsAdditionalQty({
        group_buying_order_id: this.groupId,
        group_buying_products: this.postData
      }).then(() => {
        this.backToPurchasePage();
      });
    },
    backToPurchasePage() {
      this.$router.push({
        name: 'CreatedGroupManagePurchase',
        params: this.$route.params,
        query: this.$route.query
      });
    }
  }
};
</script>
